import React, { useState } from "react";
import { useContext } from "react";
import XLLogoWhite from "../../src/assets/img/1xl-white-logo.png";
import { MoonIcon, SunIcon } from "../icons";
import { WindmillContext } from "@windmill/react-ui";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PrivacyPolicy = () => {
  const { mode, toggleMode } = useContext(WindmillContext);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(true);
  const history = useHistory();

  const closeModal = () => {
    setModal(false);
  };

  const redirect = (base) => {
    history.push(`${base}/${type ? "org" : "user"}`);
  };

  return (
    <>
      {modal && (
        <>
          {/* Backdrop with fade animation */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity z-40"
            style={{ animation: "fadeIn 0.2s ease-in" }}
          />

          {/* Modal with slide animation */}
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4" onClick={closeModal}>
            <div
              className="bg-white dark:bg-gray-800 rounded-xl shadow-xl transform transition-all max-w-lg w-full"
              style={{ animation: "slideIn 0.3s ease-out" }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Header */}
              <div className="flex justify-end p-2">
                <button
                  onClick={closeModal}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                >
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Tabs */}
              <div className="flex w-full border-b dark:border-gray-700">
                <div
                  onClick={() => setType(true)}
                  className={`flex-1 py-4 text-center cursor-pointer transition-colors relative ${
                    type
                      ? "dark:text-white text-gray-900 font-semibold"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
                  }`}
                >
                  <span>Organization</span>
                  {type && (
                    <div className="absolute bottom-0 left-0 w-full h-0.5" style={{ backgroundColor: "#e91e63" }} />
                  )}
                </div>

                <div
                  onClick={() => setType(false)}
                  className={`flex-1 py-4 text-center cursor-pointer transition-colors relative ${
                    !type
                      ? "dark:text-white text-gray-900 font-semibold"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
                  }`}
                >
                  <span>User</span>
                  {!type && (
                    <div className="absolute bottom-0 left-0 w-full h-0.5" style={{ backgroundColor: "#e91e63" }} />
                  )}
                </div>
              </div>

              {/* Content */}
              <div className="p-6 dark:bg-gray-800">
                {type ? (
                  <div className="flex justify-center">
                    <button
                      onClick={() => redirect("login")}
                      style={{ backgroundColor: "#e91e63" }}
                      className="px-8 py-3 text-white rounded-lg hover:bg-pink-700 transition-colors"
                    >
                      Login as Organization
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center gap-4">
                    <button
                      onClick={() => redirect("login")}
                      style={{ backgroundColor: "#e91e63" }}
                      className="px-8 py-3 text-white rounded-lg hover:bg-pink-700 transition-colors"
                    >
                      Login
                    </button>
                    <button
                      onClick={() => redirect("register")}
                      style={{ color: "#e91e63" }}
                      className="px-8 py-3 border border-pink-700 rounded-lg hover:bg-pink-50 dark:hover:bg-gray-700 dark:text-white dark:border-pink-700 transition-colors"
                    >
                      Register
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <style jsx>{`
            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            @keyframes slideIn {
              from {
                transform: translateY(-20px);
                opacity: 0;
              }
              to {
                transform: translateY(0);
                opacity: 1;
              }
            }
          `}</style>
        </>
      )}

      <div
        className={`flex flex-col ${mode === "dark" ? "dark-gradientt" : "banner page-banner "} relative`}
        style={{
          height: "230px",
          // backgroundColor: mode === "dark" ? "#121317" : "",
        }}
      >
        <div className="overlay w-full absolute top-0 z-30"></div>

        <div className="content-container w-full absolute top-0 z-30 flex flex-col items-center">
          <div className="w-full py-3 sm:py-6 flex justify-center">
            <div className="nav-widthhh flex justify-between gap-2 sm:gap-4 px-2">
              <Link to="/">
                <img
                  src={XLLogoWhite}
                  // height={90}
                  className="responsive-width-logo"
                  alt="Logo"
                />
              </Link>

              <div className="flex gap-2 md:gap-4 items-center">
                <button className="btn btn3" onClick={() => history.push("/auth")}>
                  ACCOUNT
                </button>
                <div className="flex align-end justify-end md:p-3">
                  <li className="flex md:px-5">
                    <button
                      className="rounded-md focus:outline-none"
                      onClick={toggleMode}
                      aria-label="Toggle color mode"
                    >
                      {mode === "dark" ? (
                        <SunIcon className="w-10 h-10 text-gray-100" aria-hidden="true" />
                      ) : (
                        <MoonIcon className="w-10 h-10 text-white" aria-hidden="true" />
                      )}
                    </button>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex align-center justify-center mt-3 md:mt-0">
            <h2 className="mb-3">
              <span
                className={`w-full title-texttt text-2xl sm:text-3xl lg:text-5xl text-center select-none font-bold ${
                  mode === "dark" ? "text-white" : "text-white"
                }`}
                style={{
                  // fontSize: "48px",
                  fontWeight: 700,
                }}
              >
                Privacy Policy
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div
        className={`px-2 sm:px-10 md:px-20 lg:px-32 xl:px-40 2xl:px-40 py-16 ${
          mode === "dark" ? "bg-darkk text-white" : "text-primaryy"
        }`}
      >
        <div className="px-2 md:p-6 space-y-6">
          <p class="text-gray-700 dark:text-gray-300 text-lg leading-relaxed mb-4">
            This privacy notice for <strong class="text-pink-700 dark:text-pink-300">ONE XL INFO LLP</strong> (
            <em>'we', 'us', or 'our'</em>), describes how and why we might collect, store, use, and/or share (
            <em>'process'</em>) your information when you use our services (<strong>'Services'</strong>).
          </p>
          <ul class="list-disc pl-5 text-gray-700 dark:text-gray-300 mb-4">
            <li>
              Visit our website at{" "}
              <a href="https://www.1XL.com" target="_blank" class="text-pink-700 dark:text-pink-300">
                www.1XL.com
              </a>{" "}
              or any website of ours that links to this privacy notice.
            </li>
            <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
          </ul>
          <p class="text-gray-700 dark:text-gray-300 text-lg leading-relaxed">
            Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices.
            If you do not agree with our policies and practices, please do not use our Services.
          </p>
          <h3 className="text-3xl md:text-4xl font-bold dark:text-white mb-6">Summary Of Key Points</h3>

          <p className="text-gray-700 dark:text-gray-300 text-lg leading-relaxed mb-4">
            This summary provides key points from our privacy notice, but you can find out more details about any of
            these topics by clicking the link following each key point or by using our table of contents below to find
            the section you are looking for.
          </p>

          <div className="space-y-6">
            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">
                What personal information do we process?
              </strong>
              <p className="text-gray-700 dark:text-gray-300">
                When you visit, use or navigate our Services, we may process personal information depending on how you
                interact with us and the Services, the choices you make and the products and features you use.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">
                Do we process any sensitive personal information?
              </strong>
              <p className="text-gray-700 dark:text-gray-300">We do not process sensitive personal information.</p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">
                Do we receive any information from third parties?
              </strong>
              <p className="text-gray-700 dark:text-gray-300">We do not receive any information from third parties.</p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">How do we process your information?</strong>
              <p className="text-gray-700 dark:text-gray-300">
                We process your information to provide, improve and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may also process your information for other
                purposes with your consent.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">
                In what situations and with which parties do we share personal information?
              </strong>
              <p className="text-gray-700 dark:text-gray-300">
                We may share information in specific situations and with specific third parties.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">
                How do we keep your information safe?
              </strong>
              <p className="text-gray-700 dark:text-gray-300">
                We have organisational and technical processes and procedures in place to protect your personal
                information. However, no electronic transmission over the internet or information storage technology can
                be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorised third parties will not be able to defeat our security and improperly collect, access,
                steal or modify your information.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">What are your rights?</strong>
              <p className="text-gray-700 dark:text-gray-300">
                Depending on where you are located geographically, the applicable privacy law may mean you have certain
                rights regarding your personal information.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">How do you exercise your rights?</strong>
              <p className="text-gray-700 dark:text-gray-300">
                The easiest way to exercise your rights is by visiting or by contacting us. We will consider and act
                upon any request in accordance with applicable data protection laws.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <strong className="text-lg text-pink-700 dark:text-pink-300">
                Want to learn more about what we do with any information we collect?
              </strong>
              <p className="text-gray-700 dark:text-gray-300">Review the privacy notice in full.</p>
            </div>
          </div>

          <h3 class="text-3xl md:text-4xl font-bold dark:text-white mb-6">Table Of Content</h3>

          <ul class="list-decimal pl-5 space-y-2 text-gray-700 dark:text-gray-300 text-lg leading-relaxed">
            <li>
              <span href="#information-collection" class="text-pink-700 dark:text-pink-300">
                What information do we collect?
              </span>
            </li>
            <li>
              <span href="#how-we-process" class="text-pink-700 dark:text-pink-300">
                How do we process your information?
              </span>
            </li>
            <li>
              <span href="#share-info" class="text-pink-700 dark:text-pink-300">
                When and with whom do we share your personal information?
              </span>
            </li>
            <li>
              <span href="#cookies-tracking" class="text-pink-700 dark:text-pink-300">
                Do we use cookies and other tracking technologies?
              </span>
            </li>
            <li>
              <span href="#social-logins" class="text-pink-700 dark:text-pink-300">
                How do we handle your social logins?
              </span>
            </li>
            <li>
              <span href="#data-retention" class="text-pink-700 dark:text-pink-300">
                How long do we keep your information?
              </span>
            </li>
            <li>
              <span href="#data-safety" class="text-pink-700 dark:text-pink-300">
                How do we keep your information safe?
              </span>
            </li>
            <li>
              <span href="#privacy-rights" class="text-pink-700 dark:text-pink-300">
                What are your privacy rights?
              </span>
            </li>
            <li>
              <span href="#do-not-track" class="text-pink-700 dark:text-pink-300">
                Controls for do-not-track features
              </span>
            </li>
            <li>
              <span href="#updates" class="text-pink-700 dark:text-pink-300">
                Do we make updates to this notice?
              </span>
            </li>
            <li>
              <span href="#contact" class="text-pink-700 dark:text-pink-300">
                How can you contact us about this notice?
              </span>
            </li>
            <li>
              <span href="#data-review" class="text-pink-700 dark:text-pink-300">
                How can you review, update or delete the data we collect from you?
              </span>
            </li>
          </ul>

          <section class="space-y-6">
            <h2 class="text-black dark:text-white text-3xl font-semibold">What information do we collect?</h2>

            <p class="text-pink-700 dark:text-pink-300 text-lg leading-relaxed hover:text-pink-800 dark:hover:text-pink-400 transition-colors duration-300">
              Personal information you disclose to us: We collect personal information that you voluntarily provide to
              us when you register on the Services, express an interest in obtaining information about us or our
              products and Services, when you participate in activities on the Services or otherwise when you contact
              us.
            </p>

            <p class="text-lg leading-relaxed">
              Personal information provided by you: The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make and the products and features you use.
              The personal information we collect may include the following:
            </p>

            <ul class="list-disc pl-6 space-y-2 text-lg">
              <li class="text-pink-700 dark:text-pink-300 hover:text-pink-800 dark:hover:text-pink-400 transition-colors duration-300">
                Names
              </li>
              <li class="text-pink-700 dark:text-pink-300 hover:text-pink-800 dark:hover:text-pink-400 transition-colors duration-300">
                Phone numbers
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Email addresses</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Mailing addresses</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Job titles</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Usernames</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Contact preferences</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Contact or authentication data</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Billing addresses</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Debit/credit card numbers</li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">Passwords</li>
            </ul>

            <h3 class="text-black dark:text-white text-2xl font-semibold">Sensitive Information</h3>

            <p class="text-lg leading-relaxed">
              We do not process sensitive information. For this privacy notice, sensitive information, also known as
              personal sensitive information, refers to data that is considered particularly sensitive or private and
              requires special protection.
            </p>

            <p class="text-pink-700 dark:text-pink-300 text-lg leading-relaxed">
              This type of information includes, but is not limited to:
            </p>

            <ul class="list-disc pl-6 space-y-2 text-lg">
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Personal Identifiers: Such as social security numbers, passport numbers, driver's licence numbers and
                other government-issued identification numbers.
              </li>
              <li class="text-pink-700 dark:text-pink-300 hover:text-pink-800 dark:hover:text-pink-400">
                Financial Information: Including bank account numbers, credit card numbers and other financial account
                information.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Health Information: Information related to an individual's physical or mental health, medical history,
                treatments or healthcare services received.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Biometric Information: Such as fingerprints, voiceprints, retina or iris scans and other biometric
                identifiers.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Genetic Information: Information about an individual's genetic tests, genetic makeup or genetic
                predisposition to certain diseases or conditions.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Information about Minors: Information about individuals under the age of 18, especially in contexts
                where parental consent is required.
              </li>
              <li class="text-pink-700 dark:text-pink-300 hover:text-pink-800 dark:hover:text-pink-400">
                Sexual Orientation or Gender Identity: Information about an individual's sexual orientation, gender
                identity or related characteristics.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Criminal History: Information about an individual's criminal history or involvement in criminal
                activities.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Political or Religious Beliefs: Information about an individual's political opinions, religious beliefs
                or other similar beliefs.
              </li>
              <li class="hover:text-pink-800 dark:hover:text-pink-400">
                Trade Union Membership: Information about an individual's membership in a trade union or similar
                organisation.
              </li>
            </ul>

            <p class="text-lg leading-relaxed">
              Sensitive information requires special handling and protection due to its potential for misuse or harm if
              disclosed or accessed without authorisation. We are committed to protecting sensitive information in
              accordance with applicable privacy laws and regulations.
            </p>

            <h3 class="text-black dark:text-white text-2xl font-semibold">Social Media Login Data</h3>

            <p class="text-lg leading-relaxed">
              We may provide you with the option to register with us using your existing social media account details,
              like your Facebook, Twitter or other social media account. If you choose to register in this way, we will
              collect the information described in the section called 'How do we handle your social logins?' below.
            </p>

            <h3 class="text-black dark:text-white text-2xl font-semibold">Information automatically collected</h3>

            <p class="text-lg leading-relaxed">
              Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics -
              is collected automatically when you visit our Services. We automatically collect certain information when
              you visit, use or navigate the Services. This information does not reveal your specific identity (like
              your name or contact information) but may include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language preferences, referring URLs, device name,
              country, location, information about how and when you use our Services and other technical information.
            </p>

            <p class="text-lg leading-relaxed">
              This information is primarily needed to maintain the security and operation of our Services and for our
              internal analytics and reporting purposes.
            </p>

            <h3 class="text-black dark:text-white text-2xl font-semibold">Log and Usage Data</h3>

            <p class="text-lg leading-relaxed">
              In addition to the personal information you provide, we also collect Log and Usage Data, which is
              service-related, diagnostic, usage and performance information our servers automatically collect when you
              access or use our Services. This information does not reveal your specific identity (such as your name or
              contact information) but may include your IP address, device information, browser type and settings and
              information about your activity in the Services (such as the date/time stamps associated with your usage,
              pages and files viewed, searches and other actions you take such as which features you use), device event
              information (such as system activity, error reports (sometimes called 'crash dumps') and hardware
              settings).
            </p>

            <h3 className="text-black dark:text-white text-2xl font-semibold">Retention</h3>

            <p class="text-lg leading-relaxed">
              We retain Log and Usage Data for as long as necessary to fulfil the purposes outlined in this privacy
              notice unless a longer retention period is required or permitted by law. When we have no ongoing
              legitimate business need to process your personal information, we will securely delete or anonymise such
              information.
            </p>

            <h3 className="text-black dark:text-white text-2xl font-semibold">Purpose</h3>

            <p class="text-lg leading-relaxed">
              We collect Log and Usage Data primarily to maintain the security and operation of our Services and for our
              internal analytics and reporting purposes. This information helps us understand how our Services are being
              used, identify usage trends, determine the effectiveness of our promotional campaigns and evaluate and
              improve our Services, products, marketing and your experience.
            </p>
          </section>

          <section class="space-y-8 px-6 py-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-6">How do we process your information?</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> We process your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to comply with the law. We may also process
              your information for other purposes with your consent. Below are the key purposes for which we process
              your personal information:
            </p>

            <ul class="list-disc pl-6 space-y-4 mt-4">
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To facilitate account creation and authentication:</strong>{" "}
                We process your information to allow you to create and log in to your account, as well as keep your
                account in working order.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To deliver and facilitate delivery of services:</strong> We
                process your information to provide you with the requested services.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To respond to user inquiries/offer support:</strong> We
                process your information to address your inquiries and resolve any potential issues you might have with
                the requested service.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To send administrative information:</strong> We may process
                your information to send details about our products and services, changes to our terms and policies, and
                other similar updates.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To fulfill and manage your orders:</strong> We process your
                information to manage your orders, payments, returns, and exchanges through the Services.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To enable user-to-user communications:</strong> We may
                process your information if you use any of our features that allow communication between users.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To request feedback:</strong> We may process your information
                when necessary to request feedback and contact you about your use of our Services.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">
                  To send you marketing and promotional communications:
                </strong>{" "}
                We may process your personal information for marketing purposes, in line with your preferences. You can
                opt-out at any time. See 'WHAT ARE YOUR PRIVACY RIGHTS?' for more details.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To deliver targeted advertising:</strong> We may process your
                information to display personalized content and advertisements based on your interests and location. See
                our Cookie Policy for more information.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To post testimonials:</strong> We may post testimonials on
                our Services that could contain personal information.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To protect our Services:</strong> We may process your
                information as part of our efforts to maintain the security of our Services, including fraud monitoring
                and prevention.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To administer prize draws and competitions:</strong> We may
                process your information to run prize draws and competitions.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To evaluate and improve our Services:</strong> We process
                your information to understand usage trends, determine the effectiveness of promotional campaigns, and
                enhance our products, marketing, and user experience.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To identify usage trends:</strong> We analyze how you use our
                Services to improve their functionality and user-friendliness.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">
                  To determine the effectiveness of marketing campaigns:
                </strong>{" "}
                We process your information to understand how to craft marketing strategies that resonate with you.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">To comply with legal obligations:</strong> We may process
                your information to comply with legal requirements, respond to legal requests, and exercise or defend
                our legal rights.
              </li>
            </ul>
          </section>

          <section class="space-y-8 px-6 py-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-6">
              When and with whom do we share your personal information?
            </h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> We may share information in specific situations described in this section
              and/or with the following third parties. We may need to share your personal information in the following
              situations:
            </p>

            <ul class="list-disc pl-6 space-y-4 mt-4">
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">Service Providers:</strong> We may engage third-party
                companies and individuals to perform services on our behalf or assist us in analysing how our Services
                are used. These service providers may have access to your personal information only to perform these
                tasks on our behalf and are obligated not to disclose or use it for any other purpose.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">Affiliates:</strong> We may share your information with our
                affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates
                include our parent company and any subsidiaries, joint venture partners, or other companies that we
                control or that are under common control with us.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">Business Partners:</strong> We may share your information
                with our business partners to offer you certain products, services, or promotions.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">Legal Compliance and Security:</strong> We may disclose your
                information if required to do so by law or in the good faith belief that such action is necessary to (a)
                comply with a legal obligation, (b) protect and defend the rights or property of Company, (c) prevent or
                investigate possible wrongdoing in connection with the Services, (d) protect the personal safety of
                users of the Services or the public, or (e) protect against legal liability.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">Merger, Sale, or Other Asset Transfers:</strong> If we are
                involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership,
                sale of company assets, or transition of service to another provider, your information may be
                transferred as part of such a transaction as permitted by law and/or contract.
              </li>
              <li class="text-gray-700 dark:text-gray-300 hover:text-pink-600 dark:hover:text-pink-400 transition-colors duration-300">
                <strong class="text-black dark:text-white">Types of Information Shared:</strong> The types of
                information we may share with these third parties include your name, email address, contact preferences,
                billing address, debit/credit card information (if applicable), and any other information you choose to
                provide to us.
              </li>
            </ul>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">
              Do we use cookies and other tracking technologies?
            </h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> We may use cookies and other tracking technologies to collect and store your
              information. We may use cookies and similar tracking technologies (like web beacons and pixels) to access
              or store information. Specific information about how we use such technologies and how you can refuse
              certain cookies is set out in our{" "}
              {/* <a href="#" class="text-pink-600 dark:text-pink-400 hover:underline"> */}
              Cookie Notice
              {/* </a> */}: Cookie Policy.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">How do we handle your social logins?</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> If you choose to register or log in to our Services using a social media
              account, we may have access to certain information about you. Our Services offer you the ability to
              register and log in using your third-party social media account details (like your Facebook or Twitter
              logins). Where you choose to do this, we will receive certain profile information about you from your
              social media provider. The profile information we receive may vary depending on the social media provider
              concerned, but will often include your name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media platform. We will use the information
              we receive only for the purposes that are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not control and are not responsible for,
              other uses of your personal information by your third-party social media provider. We recommend that you
              review their privacy notice to understand how they collect, use, and share your personal information and
              how you can set your privacy preferences on their sites and apps.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">
              How long do we keep your information?
            </h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> We keep your information for as long as necessary to fulfil the purposes
              outlined in this privacy notice unless otherwise required by law. We will only keep your personal
              information for as long as it is necessary for the purposes set out in this privacy notice, unless a
              longer retention period is required or permitted by law (such as tax, accounting, or other legal
              requirements). No purpose in this notice will require us keeping your personal information for longer than
              5 years. When we have no ongoing legitimate business need to process your personal information, we will
              either delete or anonymize such information or, if this is not possible (for example, because your
              personal information has been stored in backup archives), then we will securely store your personal
              information and isolate it from any further processing until deletion is possible.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">
              How do we keep your information safe?
            </h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> We aim to protect your personal information through a system of organisational
              and technical security measures. We have implemented appropriate and reasonable technical and
              organisational security measures designed to protect the security of any personal information we process.
              However, despite our safeguards and efforts to secure your information, no electronic transmission over
              the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise
              or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat
              our security and improperly collect, access, steal or modify your information. Although we will do our
              best to protect your personal information, transmission of personal information to and from our Services
              is at your own risk. You should only access the Services within a secure environment.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">What are your privacy rights?</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> You may review, change, or terminate your account at any time.
              <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by
              using the contact details provided in the section 'How can you contact us about this notice?' below.
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
              when applicable law allows, will it affect the processing of your personal information conducted in
              reliance on lawful processing grounds other than consent.
              <strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our
              marketing and promotional communications at any time by clicking on the unsubscribe link in the emails
              that we send or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT
              THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate
              with you for example, to send you service-related messages that are necessary for the administration and
              use of your account, to respond to service requests or for other non-marketing purposes.
              <strong>Account Information:</strong> If you would at any time like to review or change the information in
              your account or terminate your account, you can: Contact us using the contact information provided. Upon
              your request to terminate your account, we will deactivate or delete your account and information from our
              active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
              problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
              requirements.
              <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default.
              If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you
              choose to remove cookies or reject cookies, this could affect certain features or services of our
              Services. For further information, please see our Cookie Notice: Cookie Policy.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">Controls for do-not-track features</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT')
              feature or setting you can activate to signal your privacy preference not to have data about your online
              browsing activities monitored and collected. At this stage no uniform technology standard for recognising
              and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser
              signals or any other mechanism that automatically communicates your choice not to be tracked online. If a
              standard for online tracking is adopted that we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">Do we make updates to this notice?</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>In short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant
              laws. We may update this privacy notice from time to time. The updated version will be indicated by an
              updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make
              material changes to this privacy notice, we may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage you to review this privacy notice
              frequently to be informed of how we are protecting your information.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">Contact us</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              In order to resolve a complaint regarding the Services or to receive further information regarding the use
              of the Services, please contact us through our website's contact page.
            </p>
          </section>

          <section class="space-y-6 px-6 py-4">
            <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">Company Information</h2>
            <p class="text-lg text-gray-700 dark:text-gray-300">
              <strong>ONE XL INFO LLP</strong>
              <br />
              Registered Office - 3, Jainam Tower, 3, Sai Park, Belthika Nagar, Thergaon, Pune - 411033, Maharashtra,
              India
              <br />
              Corporate Office - 2nd floor, Mainai Complex, Pimple Nilakh, Vishal Nagar, Pune - 411027, Maharashtra,
              India
              <br />
              Email:{" "}
              <a href="mailto:contact@1XL.com" class="text-blue-600 dark:text-blue-400">
                contact@1XL.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
