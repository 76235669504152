import React, { useState } from "react";
import { useContext } from "react";
import XLLogoWhite from "../../src/assets/img/1xl-white-logo.png";
import { MoonIcon, SunIcon } from "../icons";
import { WindmillContext } from "@windmill/react-ui";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";

const TermsOfUse = () => {
  const { mode, toggleMode } = useContext(WindmillContext);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState(true);
  const history = useHistory();

  const closeModal = () => {
    setModal(false);
  };

  const redirect = (base) => {
    history.push(`${base}/${type ? "org" : "user"}`);
  };

  return (
    <>
      {modal && (
        <>
          {/* Backdrop with fade animation */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity z-40"
            style={{ animation: "fadeIn 0.2s ease-in" }}
          />

          {/* Modal with slide animation */}
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4" onClick={closeModal}>
            <div
              className="bg-white dark:bg-gray-800 rounded-xl shadow-xl transform transition-all max-w-lg w-full"
              style={{ animation: "slideIn 0.3s ease-out" }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Header */}
              <div className="flex justify-end p-2">
                <button
                  onClick={closeModal}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                >
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Tabs */}
              <div className="flex w-full border-b dark:border-gray-700">
                <div
                  onClick={() => setType(true)}
                  className={`flex-1 py-4 text-center cursor-pointer transition-colors relative ${
                    type
                      ? "dark:text-white text-gray-900 font-semibold"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
                  }`}
                >
                  <span>Organization</span>
                  {type && (
                    <div className="absolute bottom-0 left-0 w-full h-0.5" style={{ backgroundColor: "#e91e63" }} />
                  )}
                </div>

                <div
                  onClick={() => setType(false)}
                  className={`flex-1 py-4 text-center cursor-pointer transition-colors relative ${
                    !type
                      ? "dark:text-white text-gray-900 font-semibold"
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
                  }`}
                >
                  <span>User</span>
                  {!type && (
                    <div className="absolute bottom-0 left-0 w-full h-0.5" style={{ backgroundColor: "#e91e63" }} />
                  )}
                </div>
              </div>

              {/* Content */}
              <div className="p-6 dark:bg-gray-800">
                {type ? (
                  <div className="flex justify-center">
                    <button
                      onClick={() => redirect("login")}
                      style={{ backgroundColor: "#e91e63" }}
                      className="px-8 py-3 text-white rounded-lg hover:bg-pink-700 transition-colors"
                    >
                      Login as Organization
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center gap-4">
                    <button
                      onClick={() => redirect("login")}
                      style={{ backgroundColor: "#e91e63" }}
                      className="px-8 py-3 text-white rounded-lg hover:bg-pink-700 transition-colors"
                    >
                      Login
                    </button>
                    <button
                      onClick={() => redirect("register")}
                      style={{ color: "#e91e63" }}
                      className="px-8 py-3 border border-pink-700 rounded-lg hover:bg-pink-50 dark:hover:bg-gray-700 dark:text-white dark:border-pink-700 transition-colors"
                    >
                      Register
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <style jsx>{`
            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            @keyframes slideIn {
              from {
                transform: translateY(-20px);
                opacity: 0;
              }
              to {
                transform: translateY(0);
                opacity: 1;
              }
            }
          `}</style>
        </>
      )}

      <div
        className={`flex flex-col ${mode === "dark" ? "dark-gradientt" : "banner page-banner "} relative`}
        style={{
          height: "230px",
          // backgroundColor: mode === "dark" ? "#121317" : "",
        }}
      >
        <div className="overlay w-full absolute top-0 z-30"></div>

        <div className="content-container w-full absolute top-0 z-30 flex flex-col items-center">
          <div className="w-full py-3 sm:py-6 flex justify-center">
            <div className="nav-widthhh flex justify-between gap-2 sm:gap-4 px-2">
              <Link to="/">
                <img
                  src={XLLogoWhite}
                  // height={90}
                  className="responsive-width-logo"
                  alt="Logo"
                />
              </Link>

              <div className="flex gap-2 md:gap-4 items-center">
                <button className="btn btn3" onClick={() => history.push("/auth")}>
                  ACCOUNT
                </button>
                <div className="flex align-end justify-end md:p-3">
                  <li className="flex md:px-5">
                    <button
                      className="rounded-md focus:outline-none"
                      onClick={toggleMode}
                      aria-label="Toggle color mode"
                    >
                      {mode === "dark" ? (
                        <SunIcon className="w-10 h-10 text-gray-100" aria-hidden="true" />
                      ) : (
                        <MoonIcon className="w-10 h-10 text-white" aria-hidden="true" />
                      )}
                    </button>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex align-center justify-center mt-3 md:mt-0">
            <h2 className="mb-3">
              <span
                className={`w-full title-texttt text-2xl sm:text-3xl lg:text-5xl text-center select-none font-bold ${
                  mode === "dark" ? "text-white" : "text-white"
                }`}
                style={{
                  // fontSize: "48px",
                  fontWeight: 700,
                }}
              >
                Terms and Conditions
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div
        className={`px-2 sm:px-10 md:px-20 lg:px-32 xl:px-40 2xl:px-40 py-16 ${
          mode === "dark" ? "bg-darkk text-white" : "text-primaryy"
        }`}
      >
        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">Agreement to our legal terms</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            We are ONE XL INFO LLP ('Company', 'we', 'us' or 'our'), a company registered in India at 3, Jainam Tower,
            3, Sai Park, Belthika Nagar, Thergaon, Pune, Maharashtra, India - 411033 (the 'Company registered address').
            We operate the website{" "}
            <a href="https://www.1XL.com" target="_blank" class="text-pink-600 dark:text-pink-400">
              www.1XL.com
            </a>{" "}
            (the 'Site'), as well as any other related products and services that refer or link to these legal terms
            (the 'Legal Terms') (collectively, the 'Services'). You can contact us by email at{" "}
            <a href="mailto:contact@1XL.com" class="text-pink-600 dark:text-pink-400">
              contact@1XL.com
            </a>{" "}
            (the company mail ID) or by mail at our Company address. These Legal Terms constitute a legally binding
            agreement made between you, whether personally or on behalf of an entity ('you') and Company, concerning
            your access to and use of the Services. You agree that by accessing the Services, you have read, understood
            and agreed to be bound by all of these Legal Terms. If you do not agree with all of these legal terms, then
            you are expressly prohibited from using the services and you must discontinue use immediately. We will
            provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal
            Terms will become effective upon posting or notifying you the terms as stated in the email message. By
            continuing to use the Services after the effective date of any changes, you agree to be bound by the
            modified terms. All users who are minors in the jurisdiction in which they reside (generally under the age
            of 18) must have the permission of and be directly supervised by their parent or guardian to use the
            Services. If you are a minor, you must have your parent or guardian read and agree to these Legal Terms
            prior to you using the Services. We recommend that you print a copy of these Legal Terms for your records.
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">Table Of Content</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            Our services | Intellectual property rights | User representations | User registration | Products |
            Purchases and payment | Subscriptions | Return/refunds policy | Software | Prohibited activities | User
            generated contributions | Contribution licence | Guidelines for reviews | Social media | Services management
            | Privacy policy | Term and termination | Modifications and interruptions | Governing law | Dispute
            resolution | Corrections | Disclaimer | Limitations of liability | Indemnification | User data | Electronic
            communications, transactions and signatures | Sms text messaging | Miscellaneous | Contact us
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">1. Our services</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            The information provided when using the Services is not intended for distribution to or use by any person or
            entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation
            or which would subject us to any registration requirement within such jurisdiction or country. Accordingly,
            those persons who choose to access the Services from other locations do so on their own initiative and are
            solely responsible for compliance with local laws, if and to the extent local laws are applicable.
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">2. Intellectual property rights</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            <strong>Our intellectual property</strong>
            <br />
            We are the owner or the licensee of all intellectual property rights in our Services, including all source
            code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in
            the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained
            therein (the "Marks"). Our Content and Marks are protected by copyright and trademark laws (and various
            other intellectual property rights and unfair competition laws) and treaties in the United States and around
            the world. The Content and Marks are provided in or through the Services "AS IS" for your personal,
            non-commercial use or internal business purpose only.
          </p>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            <strong>Your use of our Services</strong>
            <br />
            Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we
            grant you a non-exclusive, non-transferable, revocable licence to: Access the Services; and Download or
            print a copy of any portion of the Content to which you have properly gained access. Solely for your
            personal, non-commercial use or internal business purpose. Except as set out in this section or elsewhere in
            our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated,
            republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written
            permission. If you wish to make any use of the Services, Content or Marks other than as set out in this
            section or elsewhere in our Legal Terms, please address your request by visiting our contact us page.
          </p>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            If we ever grant you the permission to post, reproduce or publicly display any part of our Services or
            Content, you must identify us as the owners or licensors of the Services, Content or Marks and ensure that
            any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our
            Content. We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. Any
            breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your
            right to use our Services will terminate immediately.
          </p>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            <strong>Your submissions</strong>
            <br />
            Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to using our Services to
            understand the (a) rights you give us and (b) obligations you have when you post or upload any content
            through the Services.
            <br />
            <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback or
            other information about the Services ("Submissions"), you agree to assign to us all intellectual property
            rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted
            use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You are responsible for what you post or upload: By sending us Submissions through any
            part of the Services you: Confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not
            post, send, publish, upload or transmit through the Services any Submission that is illegal, harassing,
            hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or
            group, sexually explicit, false, inaccurate, deceitful or misleading.
          </p>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            To the extent permissible by applicable law, waive any and all moral rights to any such Submission; Warrant
            that any such Submission is original to you or that you have the necessary rights and licences to submit
            such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your
            Submissions; and Warrant and represent that your Submissions do not constitute confidential information. You
            are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses
            that we may suffer because of your breach of (a) this section, (b) any third party's intellectual property
            rights or (c) applicable law.
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">3. User representations</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            By using the Services, you represent and warrant that:
            <ol class="list-decimal pl-6 space-y-2">
              <li>All registration information you submit will be true, accurate, current and complete;</li>
              <li>
                You will maintain the accuracy of such information and promptly update such registration information as
                necessary;
              </li>
              <li>You have the legal capacity and you agree to comply with these Legal Terms;</li>
              <li>
                You are not a minor in the jurisdiction in which you reside or if a minor, you have received parental
                permission to use the Services;
              </li>
              <li>
                You will not access the Services through automated or non-human means, whether through a bot, script or
                otherwise;
              </li>
              <li>You will not use the Services for any illegal or unauthorised purpose; and</li>
              <li>Your use of the Services will not violate any applicable law or regulation.</li>
            </ol>
            If you provide any information that is untrue, inaccurate, not current or incomplete, we have the right to
            suspend or terminate your account and refuse any and all current or future use of the Services (or any
            portion thereof).
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">4. User registration</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            You may be required to register to use the Services. You agree to keep your password confidential and will
            be responsible for all use of your account and password. We reserve the right to remove, reclaim or change a
            username you select if we determine, in our sole discretion, that such username is inappropriate, obscene or
            otherwise objectionable.
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">5. Products</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            We make every effort to display as accurately as possible the colours, features, specifications and details
            of the products available on the Services. However, we do not guarantee that the colours, features,
            specifications and details of the products will be accurate, complete, reliable, current or free of other
            errors and your electronic display may not accurately reflect the actual colours and details of the
            products. All products are subject to availability and we cannot guarantee that items will be in stock. We
            reserve the right to discontinue any products at any time for any reason. Prices for all products are
            subject to change.
          </p>
        </section>

        <section class="space-y-6 px-6 py-4">
          <h2 class="text-3xl font-semibold text-black dark:text-white mb-4">6. Purchases and payment</h2>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            We accept the following forms of payment:
            <ul class="list-disc pl-6 space-y-2">
              <li>Visa</li>
              <li>Mastercard</li>
              <li>American Express</li>
              <li>Discover</li>
              <li>PayPal</li>
            </ul>
            You agree to provide current, complete and accurate purchase and account information for all purchases made
            via the Services. You further agree to promptly update account and payment information, including email
            address, payment method and payment card expiration date, so that we can complete your transactions and
            contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may
            change prices at any time. All payments shall be in US dollars. You agree to pay all charges at the prices
            then in effect for your purchases and any applicable shipping fees and you authorise us to charge your
            chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any
            errors or mistakes in pricing, even if we have already requested or received payment.
          </p>
          <p class="text-lg text-gray-700 dark:text-gray-300">
            We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit
            or cancel quantities purchased per person, per household or per order. These restrictions may include orders
            placed by or under the same customer account, the same payment method and/or orders that use the same
            billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgement,
            appear to be placed by dealers, resellers or distributors.
          </p>
        </section>

        <section class="py-8 px-4 rounded-lg">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-white">7. Subscriptions Billing and Renewal</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            Your subscription will continue and automatically renew unless cancelled. You consent to our charging your
            payment method on a recurring basis without requiring your prior approval for each recurring charge, until
            such time as you cancel the applicable order. The length of your billing cycle will depend on the type of
            subscription plan you choose when you subscribed to the Services.
          </p>
          <h3 class="font-medium text-gray-800 dark:text-white mt-4">Free Trial</h3>
          <p class="text-gray-700 dark:text-gray-300">
            We offer a 7-day free trial to new users who register with the Services. The account will be charged
            according to the user's chosen subscription at the end of the free trial.
          </p>
          <h3 class="font-medium text-gray-800 dark:text-white mt-4">Cancellation</h3>
          <p class="text-gray-700 dark:text-gray-300">
            You can cancel your subscription at any time by logging into your account. Your cancellation will take
            effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services,
            please email us at the company mail ID.
          </p>
          <h3 class="font-medium text-gray-800 dark:text-white mt-4">Fee Changes</h3>
          <p class="text-gray-700 dark:text-gray-300">
            We may, from time to time, make changes to the subscription fee and will communicate any price changes to
            you in accordance with applicable law.
          </p>
        </section>

        <section class="py-8 px-4  rounded-lg mt-6">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-white">8. Return/Refunds policy</h2>
          <h3 class="font-medium text-gray-800 dark:text-white mt-4">Return policy for credit-based products</h3>
          <p class="text-gray-700 dark:text-gray-300">
            Our credit-based products are non-refundable. Once credits are purchased, they cannot be returned or
            exchanged for cash or other products. Please ensure that you are ready to use your credits before making a
            purchase.
          </p>
          <h3 class="font-medium text-gray-800 dark:text-white mt-4">Return policy for subscription-based products</h3>
          <p class="text-gray-700 dark:text-gray-300">
            Subscription-based products can be cancelled at any time, but we do not offer refunds for partially used
            subscription periods. If you cancel your subscription, you will continue to have access to the service until
            the end of your current billing cycle.
          </p>
          <h3 class="font-medium text-gray-800 dark:text-white mt-4">Return policy for free products</h3>
          <p class="text-gray-700 dark:text-gray-300">
            Our free products are provided as-is and we do not offer refunds for these items. If you encounter any
            issues or have feedback about our free products, please let us know and we will do our best to address your
            concerns.
          </p>
        </section>

        <section class="py-8 px-4  rounded-lg mt-6">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-white">9. Software</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            We may include software for use in connection with our Services. If such software is accompanied by an end
            user licence agreement ('EULA'), the terms of the EULA will govern your use of the software. If such
            software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal and
            non-transferable licence to use such software solely in connection with our services and in accordance with
            these Legal Terms. Any software and any related documentation is provided 'AS IS' without warranty of any
            kind, either express or implied, including, without limitation, the implied warranties of merchantability,
            fitness for a particular purpose or non-infringement.
          </p>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            You accept any and all risk arising out of use or performance of any software. You may not reproduce or
            redistribute any software except in accordance with the EULA or these Legal Terms.
          </p>
        </section>

        <section class="py-8 px-4  rounded-lg mt-6">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-white">10. Prohibited activities</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            You may not access or use the Services for any purpose other than that for which we make the Services
            available. The Services may not be used in connection with any commercial endeavours except those that are
            specifically endorsed or approved by us. As a user of the Services, you agree not to:
          </p>
          <ul class="list-disc list-inside text-gray-700 dark:text-gray-300 mt-4">
            <li>
              Systematically retrieve data or other content from the Services to create or compile, directly or
              indirectly, a collection, compilation, database or directory without written permission from us.
            </li>
            <li>
              Trick, defraud or mislead us and other users, especially in any attempt to learn sensitive account
              information such as user passwords.
            </li>
            <li>
              Circumvent, disable or otherwise interfere with security-related features of the Services, including
              features that prevent or restrict the use or copying of any Content or enforce limitations on the use of
              the Services and/or the Content contained therein.
            </li>
            <li>Disparage, tarnish or otherwise harm, in our opinion, us and/or the Services.</li>
            <li>Use any information obtained from the Services in order to harass, abuse or harm another person.</li>
            <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
            <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
            <li>Engage in unauthorised framing of or linking to the Services.</li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses or other material,
              including excessive use of capital letters and spamming (continuous posting of repetitive text), that
              interferes with any party's uninterrupted use and enjoyment of the Services or modifies, impairs,
              disrupts, alters or interferes with the use, features, functions, operation or maintenance of the
              Services.
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts to send comments or messages or using any
              data mining, robots or similar data gathering and extraction tools.
            </li>
            <li>Delete the copyright or other proprietary rights notice from any Content.</li>
            <li>Attempt to impersonate another user or person or use the username of another user.</li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
              information collection or transmission mechanism, including without limitation, clear graphics interchange
              formats ('gifs'), 1x1 pixels, web bugs, cookies or other similar devices (sometimes referred to as
              'spyware' or 'passive collection mechanisms' or 'pcms').
            </li>
            <li>
              Interfere with, disrupt or create an undue burden on the Services or the networks or services connected to
              the Services.
            </li>
            <li>
              Harass, annoy, intimidate or threaten any of our employees or agents engaged in providing any portion of
              the Services to you.
            </li>
            <li>
              Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services or
              any portion of the Services.
            </li>
            <li>
              Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript or other
              code.
            </li>
            <li>
              Except as permitted by applicable law, decipher, decompile, disassemble or reverse engineer any of the
              software comprising or in any way making up a part of the Services.
            </li>
            <li>
              Except as may be the result of standard search engine or Internet browser usage, use, launch, develop or
              distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper
              or offline reader that accesses the Services or use or launch any unauthorised script or other software.
            </li>
            <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
            <li>
              Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users
              by electronic or other means for the purpose of sending unsolicited email or creating user accounts by
              automated means or under false pretences.
            </li>
            <li>
              Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content
              for any revenue-generating endeavour or commercial enterprise.
            </li>
            <li>Use the Services to advertise or offer to sell goods and services.</li>
            <li>Sell or otherwise transfer your profile.</li>
          </ul>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">11. User Generated Contributions</h2>
          <p class="text-lg">The Services do not offer users the ability to submit or post content.</p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">12. Contribution Licence</h2>
          <p class="text-lg">
            You and the Services agree that we may access, store, process, and use any information and personal data
            that you provide, following the terms of the Privacy Policy and your choices (including settings). By
            submitting suggestions or other feedback regarding the Services, you agree that we can use and share such
            feedback for any purpose without compensation to you.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">13. Guidelines for Reviews</h2>
          <p class="text-lg">
            We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply
            with the following criteria:
          </p>
          <ul class="list-disc list-inside text-lg ml-4">
            <li>You should have firsthand experience with the person/entity being reviewed;</li>
            <li>
              Your reviews should not contain offensive profanity or abusive, racist, offensive, or hateful language;
            </li>
            <li>
              Your reviews should not contain discriminatory references based on religion, race, gender, national
              origin, age, marital status, sexual orientation, or disability;
            </li>
            <li>Your reviews should not contain references to illegal activity;</li>
            <li>You should not be affiliated with competitors if posting negative reviews;</li>
            <li>You should not make any conclusions as to the legality of conduct;</li>
            <li>You may not post any false or misleading statements;</li>
            <li>You may not organise a campaign encouraging others to post reviews, whether positive or negative.</li>
          </ul>
          <p class="text-lg">
            We may accept, reject, or remove reviews at our sole discretion. We have absolutely no obligation to screen
            reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not
            endorsed by us and do not necessarily represent our opinions or the views of any of our affiliates or
            partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from
            any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free,
            fully paid, assignable, and sublicensable right and licence to reproduce, modify, translate, transmit by any
            means, display, perform, and/or distribute all content relating to review.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">14. Social Media</h2>
          <p class="text-lg">
            As part of the functionality of the Services, you may link your account with online accounts you have with
            third-party service providers (each such account, a 'Third-Party Account') by either:
          </p>
          <ul class="list-decimal list-inside text-lg ml-4">
            <li>Providing your Third-Party Account login information through the Services; or</li>
            <li>
              Allowing us to access your Third-Party Account, as is permitted under the applicable terms and conditions
              that govern your use of each Third-Party Account.
            </li>
          </ul>
          <p class="text-lg">
            You represent and warrant that you are entitled to disclose your Third-Party Account login information to us
            and/or grant us access to your Third-Party Account, without breach by you of any of the terms and conditions
            that govern your use of the applicable Third-Party Account and without obligating us to pay any fees or
            making us subject to any usage limitations imposed by the third-party service provider of the Third-Party
            Account.
          </p>
          <p class="text-lg">By granting us access to any Third-Party Accounts, you understand that:</p>
          <ul class="list-decimal list-inside text-lg ml-4">
            <li>
              We may access, make available and store (if applicable) any content that you have provided to and stored
              in your Third-Party Account ('Social Network Content') so that it is available on and through the Services
              via your account, including without limitation any friend lists;
            </li>
            <li>
              We may submit to and receive from your Third-Party Account additional information to the extent you are
              notified when you link your account with the Third-Party Account.
            </li>
          </ul>
          <p class="text-lg">
            Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in
            such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts
            may be available on and through your account on the Services.
          </p>
          <p class="text-lg">
            Please note that if a Third-Party Account or associated service becomes unavailable or our access to such
            Third-Party Account is terminated by the third-party service provider, then Social Network Content may no
            longer be available on and through the Services.
          </p>
          <p class="text-lg">
            You will have the ability to disable the connection between your account on the Services and your
            Third-Party Accounts at any time. Please note that your relationship with the third-party service providers
            associated with your third-party accounts is governed solely by your agreement(s) with such third-party
            service providers. We make no effort to review any Social Network Content for any purpose, including but not
            limited to, for accuracy, legality or non-infringement and we are not responsible for any Social Network
            Content.
          </p>
          <p class="text-lg">
            You acknowledge and agree that we may access your email address book associated with a Third-Party Account
            and your contacts list stored on your mobile device or tablet computer solely for purposes of identifying
            and informing you of those contacts who have also registered to use the Services. You can deactivate the
            connection between the Services and your Third-Party Account by contacting us using the contact information
            below or through your account settings (if applicable). We will attempt to delete any information stored on
            our servers that was obtained through such Third-Party Account, except the username and profile picture that
            become associated with your account.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">15. Services Management</h2>
          <p class="text-lg">We reserve the right, but not the obligation, to:</p>
          <ul class="list-decimal list-inside text-lg ml-4">
            <li>Monitor the Services for violations of these Legal Terms;</li>
            <li>
              Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal
              Terms, including without limitation, reporting such user to law enforcement authorities;
            </li>
            <li>
              In our sole discretion and without limitation, refuse, restrict access to, limit the availability of or
              disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
            </li>
            <li>
              In our sole discretion and without limitation, notice or liability, to remove from the Services or
              otherwise disable all files and content that are excessive in size or are in any way burdensome to our
              systems;
            </li>
            <li>
              Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate
              the proper functioning of the Services.
            </li>
          </ul>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">16. Privacy Policy</h2>
          <p class="text-lg">
            We care about data privacy and security. Please review our Privacy Policy: By using the Services, you agree
            to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the
            Services are hosted in India and the United Arab Emirates. If you access the Services from any other region
            of the world with laws or other requirements governing personal data collection, use or disclosure that
            differ from applicable laws in India and United Arab Emirates, then through your continued use of the
            Services, you are transferring your data to India and United Arab Emirates and you expressly consent to have
            your data transferred to and processed in India and United Arab Emirates.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">17. Term and Termination</h2>
          <p class="text-lg">
            These Legal Terms shall remain in full force and effect while you use the Services. Without limiting any
            other provision of these legal terms, we reserve the right to, in our sole discretion and without notice or
            liability, deny access to and use of the services (including blocking certain IP addresses), to any person
            for any reason or for no reason, including without limitation for breach of any representation, warranty or
            covenant contained in these legal terms or of any applicable law or regulation. We may terminate your use or
            participation in the services or delete your account and any content or information that you posted at any
            time, without warning, at our sole discretion. If we terminate or suspend your account for any reason, you
            are prohibited from registering and creating a new account under your name, a fake or borrowed name or the
            name of any third party, even if you may be acting on behalf of the third party. In addition to terminating
            or suspending your account, we reserve the right to take appropriate legal action, including without
            limitation pursuing civil, criminal and injunctive redress.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">18. Modifications and Interruptions</h2>
          <p class="text-lg">
            We reserve the right to change, modify or remove the contents of the Services at any time or for any reason
            at our sole discretion without notice. However, we have no obligation to update any information on our
            Services. We will not be liable to you or any third party for any modification, price change, suspension or
            discontinuance of the Services. We cannot guarantee the Services will be available at all times. We may
            experience hardware, software or other problems or need to perform maintenance related to the Services,
            resulting in interruptions, delays or errors. We reserve the right to change, revise, update, suspend,
            discontinue or otherwise modify the Services at any time or for any reason without notice to you. You agree
            that we have no liability whatsoever for any loss, damage or inconvenience caused by your inability to
            access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal
            Terms will be construed to obligate us to maintain and support the Services or to supply any corrections,
            updates or releases in connection therewith.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">19. Governing Law</h2>
          <p class="text-lg">
            These Legal Terms shall be governed by and defined following the laws of India. The Company and you
            irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute which
            may arise in connection with these Legal Terms.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">20. Dispute Resolution</h2>
          <h3 class="text-xl font-semibold mb-2">Informal Negotiations</h3>
          <p class="text-lg">
            To expedite resolution and control the cost of any dispute, controversy or claim related to these Legal
            Terms (each a 'Dispute' and collectively, the 'Disputes') brought by either you or us (individually, a
            'Party' and collectively, the 'Parties'), the Parties agree to first attempt to negotiate any Dispute
            (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating
            arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
          </p>
          <h3 class="text-xl font-semibold mb-2">Binding Arbitration</h3>
          <p class="text-lg">
            Any dispute arising out of or in connection with these Legal Terms, including any question regarding its
            existence, validity or termination, shall be referred to and finally resolved by the International
            Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146)
            according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of
            this clause. The number of arbitrators shall be four (4). The seat or legal place or arbitration shall be
            Pune, India. The language of the proceedings shall be English. The governing law of these Legal Terms shall
            be substantive law of India.
          </p>
          <h3 class="text-xl font-semibold mb-2">Restrictions</h3>
          <p class="text-lg">
            The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To
            the full extent permitted by law, (a) No arbitration shall be joined with any other proceeding; (b) There is
            no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class action
            procedures; and (c) There is no right or authority for any Dispute to be brought in a purported
            representative capacity on behalf of the general public or any other persons.
          </p>
          <h3 class="text-xl font-semibold mb-2">Exceptions to Informal Negotiations and Arbitration</h3>
          <p class="text-lg">
            The Parties agree that the following Disputes are not subject to the above provisions concerning informal
            negotiations binding arbitration: (a) Any Disputes seeking to enforce or protect or concerning the validity
            of, any of the intellectual property rights of a Party; (b) Any Dispute related to or arising from,
            allegations of theft, piracy, invasion of privacy or unauthorised use; and (c) Any claim for injunctive
            relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to
            arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and
            such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction
            above and the Parties agree to submit to the personal jurisdiction of that court.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">21. Corrections</h2>
          <p class="text-lg">
            There may be information on the Services that contains typographical errors, inaccuracies or omissions,
            including descriptions, pricing, availability and various other information. We reserve the right to correct
            any errors, inaccuracies or omissions and to change or update the information on the Services at any time,
            without prior notice.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">22. Disclaimer</h2>
          <p class="text-lg">
            The services are provided on an as-is and as-available basis. you agree that your use of the services will
            be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or
            implied, in connection with the services and your use thereof, including, without limitation, the implied
            warranties of merchantability, fitness for a particular purpose and non-infringement.
          </p>
          <ul class="list-decimal list-inside text-lg ml-4">
            <li>
              We make no warranties or representations about the accuracy or completeness of the services' content or
              the content of any websites or mobile applications linked to the services and we will assume no liability
              or responsibility for any:
            </li>
            <li>Errors, mistakes or inaccuracies of content and materials,</li>
            <li>
              Personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the
              services,
            </li>
            <li>
              Any unauthorised access to or use of our secure servers and/or any and all personal information and/or
              financial information stored therein,
            </li>
            <li>Any interruption or cessation of transmission to or from the services,</li>
            <li>
              Any bugs, viruses, trojan horses or the like which may be transmitted to or through the services by any
              third party and/or,
            </li>
            <li>
              Any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a
              result of the use of any content posted, transmitted or otherwise made available via the services.
            </li>
          </ul>
          <p class="text-lg">
            We do not warrant, endorse, guarantee or assume responsibility for any product or service advertised or
            offered by a third party through the services, any hyperlinked website or any website or mobile application
            featured in any banner or other advertising and we will not be a party to or in any way be responsible for
            monitoring any transaction between you and any third-party providers of products or services. As with the
            purchase of a product or service through any medium or in any environment, you should use your best
            judgement and exercise caution where appropriate.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">23. Limitations of Liability</h2>
          <p class="text-lg">
            In no event will we or our directors, employees or agents be liable to you or any third party for any
            direct, indirect, consequential, exemplary, incidental, special or punitive damages, including lost profit,
            lost revenue, loss of data or other damages arising from your use of the services, even if we have been
            advised of the possibility of such damages.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">24. Indemnification</h2>
          <p class="text-lg">
            You agree to defend, indemnify and hold us harmless, including our subsidiaries, affiliates and all of our
            respective officers, agents, partners and employees, from and against any loss, damage, liability, claim or
            demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of:
          </p>
          <ul class="list-decimal list-inside text-lg ml-4">
            <li>Use of the Services;</li>
            <li>Breach of these Legal Terms;</li>
            <li>Any breach of your representations and warranties set forth in these Legal Terms;</li>
            <li>
              Your violation of the rights of a third party, including but not limited to intellectual property rights;
            </li>
            <li>
              Any overt harmful act toward any other user of the Services with whom you connected via the Services.
            </li>
          </ul>
          <p class="text-lg">
            Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and
            control of any matter for which you are required to indemnify us and you agree to cooperate, at your
            expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim,
            action or proceeding which is subject to this indemnification upon becoming aware of it.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">25. User Data</h2>
          <p class="text-lg">
            We will maintain certain data that you transmit to the Services for the purpose of managing the performance
            of the Services, as well as data relating to your use of the Services. Although we perform regular routine
            backups of data, you are solely responsible for all data that you transmit or that relates to any activity
            you have undertaken using the Services. You agree that we shall have no liability to you for any loss or
            corruption of any such data and you hereby waive any right of action against us arising from any such loss
            or corruption of such data.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">26. Electronic Communications, Transactions, and Signatures</h2>
          <p class="text-lg">
            Visiting the Services, sending us emails and completing online forms constitute electronic communications.
            You consent to receive electronic communications and you agree that all agreements, notices, disclosures and
            other communications we provide to you electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing.
          </p>
          <p class="text-lg">
            You hereby agree to the use of electronic signatures, contracts orders and other records and to electronic
            delivery of notices, policies and records of transactions initiated or completed by us or via the services.
            You hereby waive any rights or requirements under any statutes, regulations, rules ordinances or other laws
            in any jurisdiction which require an original signature or delivery or retention of non-electronic records
            or to payments or the granting of credits by any means other than electronic means.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">27. SMS Text Messaging</h2>
          <p class="text-lg">
            <strong>Message Frequency:</strong> Weekly and on festivals
          </p>
          <p class="text-lg">
            <strong>Opting Out:</strong> If at any time you wish to stop receiving SMS messages from us, simply reply to
            the text with "STOP." You may receive an SMS message confirming your opt out.
          </p>
          <p class="text-lg">
            <strong>Message and Data Rates:</strong> Please be aware that message and data rates may apply to any SMS
            messages sent or received. The rates are determined by your carrier and the specifics of your mobile plan.
          </p>
          <p class="text-lg">
            <strong>Support:</strong> If you have any questions or need assistance regarding our SMS communications,
            please email us at the company mail ID.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">28. Miscellaneous</h2>
          <p class="text-lg">
            These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the
            Services constitute the entire agreement and understanding between you and us. Our failure to exercise or
            enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
            provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of
            our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
            damage, delay or failure to act caused by any cause beyond our reasonable control.
          </p>
          <p class="text-lg">
            If any provision or part of a provision of these Legal Terms is determined to be unlawful, void or
            unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does
            not affect the validity and enforceability of any remaining provisions.
          </p>
          <p class="text-lg">
            There is no joint venture, partnership, employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed
            against us by virtue of having drafted them. You hereby waive any and all defences you may have based on the
            electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal
            Terms.
          </p>
        </section>

        <section class="my-8">
          <h2 class="text-3xl font-semibold mb-4">29. Contact Us</h2>
          <p class="text-lg">
            In order to resolve a complaint regarding the Services or to receive further information regarding use of
            the Services, please contact us at:
          </p>
          <p class="text-lg">
            <strong>ONE XL INFO LLP</strong>
            <br />
            Registered Office - 3, Jainam Tower, 3, Sai Park, Belthika Nagar, Thergaon, Pune - 411033, Maharashtra,
            India
            <br />
            Corporate Office - 2nd floor, Mainai Complex, Pimple Nilakh, Vishal Nagar, Pune - 411027, Maharashtra, India
            <br />
            <strong>Email:</strong> contact@1XL.com
          </p>
        </section>
      </div>
    </>
  );
};

export default TermsOfUse;
